/**
 * External dependencies
 */
import jwt_decode from 'jwt-decode';
import createSelector from 'rememo';
import {
	map,
	filter,
	isNumber,
} from 'lodash';

/**
 * Internal dependencies
 */
import getQueryParts from './utils/get-query-parts';
import {
	AppState,
	AppStateDistrict,
	Profile,
	Notice,
	Activity,
	AppStateOrganizationItem,
	AppStateMenuItems,
} from './types';

const isTokenExpired = ( token ) => {
	const currentTime = Date.now() / 1000;
	return token.exp < currentTime;
};

/**
 * @param {AppState} state Global application state.
 */
export function isAuthenticated( state: AppState ): boolean {
	const token = getDecodedToken( state );
	if ( ! token ) {
		return false;
	}

	return ! isTokenExpired( token );
}

/**
 * @param {AppState} state Global application state.
 */
export function getAuthenticatedUserId( state: AppState ) {
	const token = getDecodedToken( state );
	if ( ! token ) {
		return 0;
	}

	return token.sub;
}

/**
 * @param {AppState} state Global application state.
 */
export function isProfileFetching( state: AppState ) {
	return state.profile.isFetching || false;
}

/**
 * @param {AppState} state Global application state.
 */
export function isProfileUpdating( state: AppState ) {
	return state.profile.isUpdating || false;
}

/**
 * Returns the profile data.
 *
 * @param {AppState} state Global application state.
 * @return {Object} Whether the profile has results.
 */
export function getProfile( state: AppState ): Profile | {} {
	return state.profile.data || {};
}

/**
 * Returns the open/close state of global LoginModal.
 *
 * @param {AppState} state Global application state.
 * @return {Boolean} Whether the modal is opened or closed.
 */
export function isLoginModalOpen( state: AppState ) {
	return state.loginModal;
}

/**
 * Whether the current profile has results for the interests check.
 *
 * @param {AppState} state Global application state.
 * @return {boolean} Whether the profile has results.
 */
export function profileHasInterestsCheckResults( state: AppState ) {
	const profile = getProfile( state );
	return profile.hasOwnProperty( 'interests_check_results' );
}

/**
 * @param {AppState} state Global application state.
 */
export function getRegistrationError( state: AppState ) {
	return state.authentication.error;
}

/**
 * Returns whether the user is logging in.
 */
export function isLoggingIn( state: AppState ) {
	return state.authentication.loggingIn || false;
}

/**
 * Returns whether the user is registering a new account.
 */
export function isRegistering( state: AppState ) {
	return state.authentication.registering || false;
}

/**
 * Returns whether the login failed.
 */
export function isLoginFailed( state: AppState ) {
	return !! state.authentication.error;
}

/**
 * Returns the current auth token.
 */
export function getToken( state: AppState ) {
	return state.token;
}

/**
 * Returns the current decoded auth token.
 */
export const getDecodedToken = createSelector(
	( state: AppState ) => {
		try {
			return jwt_decode( state.token );
		} catch ( error ) {
			return null;
		}
	},
	( state: AppState ) => [ state.token ],
);

/**
 * Returns all notices as an array.
 *
 * @param {AppState} state Global application state.
 * @return {Notice[]} Array of notices.
 */
export function getNotices( state: AppState ): Array<Notice> {
	return state.notices;
}

/**
 * Returns true if the page with the given path is being fetched, or
 * false otherwise.
 *
 * @param {AppState} state        Global application state.
 * @param {string}   pagePath     The page's path.
 * @param {string}   pagePassword The page's password.
 * @return {boolean} Whether the page is being fetched.
 */
export function isPageFetching( state: AppState, pagePath: string, pagePassword: string ) {
	const pageKey = `${ pagePath }${ pagePassword }`;
	return !! state.pages.isFetching[ pageKey ];
}

/**
 * Returns the page with the given path.
 *
 * @param {AppState} state        Global application state.
 * @param {string}   pagePath     The page's path.
 * @param {string}   pagePassword The page's password.
 * @return {Object} The page, or null if none exists.
 */
export function getPage( state: AppState, pagePath: string, pagePassword: string ) {
	const pageKey = `${ pagePath }${ pagePassword }`;
	return state.pages.results[ pageKey ] || null;
}

/**
 * Returns the error when a request has failed.
 *
 * @param {AppState} state        Global application state.
 * @param {string}   pagePath     The page's path.
 * @param {string}   pagePassword The page's password.
 * @return {Object} The error, or null if none exists.
 */
export function getPageError( state: AppState, pagePath: string, pagePassword: string ) {
	const pageKey = `${ pagePath }${ pagePassword }`;
	return state.pages.errors[ pageKey ] || null;
}

/**
 * @param {AppState} state Global application state.
 */
export function getSchools( state: AppState ) {
	return state.schools.results;
}

/**
 * @param {AppState} state Global application state.
 */
export function isSchoolsFetching( state: AppState ) {
	return state.schools.isFetching || false;
}

/**
 * @param {AppState} state    Global application state.
 * @param {number}   schoolID ID of a school.
 */
export function getTeachers( state: AppState, schoolID ) {
	return state.teachers.results[ schoolID ] || [];
}

/**
 * @param {AppState} state    Global application state.
 * @param {number}   schoolID ID of a school.
 */
export function isTeachersFetching( state: AppState, schoolID ) {
	return state.teachers.isFetching[ schoolID ] || false;
}

/**
 * @param {AppState} state Global application state.
 * @param {Object=}  query Optional query.
 * @return {Array} Districts.
 */
export function getDistricts( state: AppState, query = {} ): AppStateDistrict[] {
	return getEntityRecords( state, 'districts', query );
}

/**
 * @param {AppState} state Global application state.
 * @param {Object=}  query Optional query.
 * @return {Boolean} Whether a fetch is processing.
 */
export function isDistrictsFetching( state: AppState, query = {} ) {
	return isEntityRecordsFetching( state, 'districts', query );
}

/**
 * @param {AppState} state Global application state.
 */
export function getCities( state: AppState ) {
	return state.cities.results;
}

/**
 * @param {AppState} state Global application state.
 */
export function isCitiesFetching( state: AppState ) {
	return state.cities.isFetching || false;
}

/**
 * @param {AppState} state Global application state.
 */
export function getEventAudience( state: AppState ) {
	return state.eventAudience.results;
}

/**
 * @param {AppState} state Global application state.
 */
export function isEventAudienceFetching( state: AppState ) {
	return state.eventAudience.isFetching || false;
}

/**
 * @param {AppState} state Global application state.
 */
export function getEventTopics( state: AppState ) {
	return state.eventTopics.results;
}

/**
 * @param {AppState} state Global application state.
 */
export function isEventTopicsFetching( state: AppState ) {
	return state.eventTopics.isFetching || false;
}

/**
 * Returns items for a given query, or null if the items are not known.
 *
 * @param {Object}  state State object.
 * @param {Object=} query Optional query.
 * @return {?Array} Query items.
 */
function getQueriedItems( state, query ): any[] {
	const {
		stableKey,
		page,
		perPage,
	} = getQueryParts( query );

	if ( ! state.queries[ stableKey ] ) {
		return null;
	}

	const itemIds = state.queries[ stableKey ];
	if ( ! itemIds ) {
		return null;
	}

	const startOffset = perPage === -1 ? 0 : ( page - 1 ) * perPage;
	const endOffset = perPage === -1 ? itemIds.length : Math.min(
		startOffset + perPage,
		itemIds.length,
	);

	const items = [];
	for ( let i = startOffset; i < endOffset; i++ ) {
		const itemId = itemIds[ i ];
		items.push( state.items[ itemId ] );
	}

	return items;
}

/**
 * Returns the Entity's records.
 *
 * @param {Object}  state State tree.
 * @param {string}  name  Entity name.
 * @param {Object=} query Optional query.
 * @return {Array} Records.
 */
export function getEntityRecords( state: AppState, name: string, query = {} ): any[] {
	const queriedState = state[ name ] || null;
	if ( ! queriedState ) {
		return [];
	}
	return getQueriedItems( queriedState, query ) || [];
}

/**
 * Whether the Entity's records are currently fetched.
 *
 * @param {Object}  state State tree.
 * @param {string}  name  Entity name.
 * @param {Object=} query Optional query.
 * @return {Boolean} Whether a fetch is processing.
 */
export function isEntityRecordsFetching( state: AppState, name: string, query = {} ) {
	const { stableKey } = getQueryParts( query );
	return !! state[ name ].isFetching[ stableKey ];
}

/**
 * @typedef {Object} PaginationDetails Object describing pagination.
 * @property {Number} total      Count of all results.
 * @property {Number} totalPages Count of total pages.
 */

/**
 * Returns the pagination details for Entity's records.
 *
 * @param {Object}  state State tree.
 * @param {string}  name  Entity name.
 * @param {Object=} query Optional query.
 * @return {PaginationDetails} Pagination details.
 */
export function getEntityRecordsPagination( state: AppState, name, query = {} ) {
	const { stableKey } = getQueryParts( query );
	return state[ name ].pagination[ stableKey ] || {};
}

/**
 * @param {AppState} state Global application state.
 * @param {string}   slug  The slug.
 */
export function getProfession( state: AppState, slug ) {
	return state.professions.items[ slug ];
}

/**
 * @param {AppState} state Global application state.
 * @param {string}   slug  The slug.
 */
export function isProfessionFetching( state: AppState, slug ) {
	return isEntityRecordsFetching( state, 'professions', { slug } );
}

/**
 * @param {AppState} state Global application state.
 */
export function getProfessionFields( state: AppState ) {
	return state.professionFields.results;
}

/**
 * @param {AppState} state Global application state.
 */
export function isProfessionFieldsFetching( state: AppState ) {
	return state.professionFields.isFetching || false;
}

/**
 * @param {AppState} state Global application state.
 */
export function getProfessionInterests( state: AppState ) {
	return state.professionInterests.results;
}

/**
 * @param {AppState} state Global application state.
 */
export function isProfessionInterestsFetching( state: AppState ) {
	return state.professionInterests.isFetching || false;
}

/**
 * @param {AppState} state Global application state.
 */
export function getProfessionSectors( state: AppState ) {
	return state.professionSectors.results;
}

/**
 * @param {AppState} state Global application state.
 */
export function isProfessionSectorsFetching( state: AppState ) {
	return state.professionSectors.isFetching || false;
}

/**
 * @param {AppState} state Global application state.
 */
export function getProfessionDurations( state: AppState ) {
	return state.professionDurations.results;
}

/**
 * @param {AppState} state Global application state.
 */
export function isProfessionDurationsFetching( state: AppState ) {
	return state.professionDurations.isFetching || false;
}

/**
 * @param {AppState} state Global application state.
 */
export function getProfessionQualifications( state: AppState ) {
	return state.professionQualifications.results;
}

/**
 * @param {AppState} state Global application state.
 */
export function isProfessionQualificationsFetching( state: AppState ) {
	return state.professionQualifications.isFetching || false;
}

/**
 * @param {AppState} state Global application state.
 */
export function getProfessionBilingualEducation( state: AppState ) {
	return state.professionBilingualEducation.results;
}

/**
 * @param {AppState} state Global application state.
 */
export function isProfessionBilingualEducationFetching( state: AppState ) {
	return state.professionBilingualEducation.isFetching || false;
}

/**
 * @param {AppState} state Global application state.
 * @param {string}   slug  The slug.
 */
export function getEvent( state: AppState, slug: string ) {
	return state.events.items[ slug ];
}

/**
 * @param {AppState} state Global application state.
 * @param {string}   slug  The slug.
 */
export function isEventFetching( state: AppState, slug: string ) {
	return isEntityRecordsFetching( state, 'events', { slug } );
}

/**
 * @param {AppState} state Global application state.
 * @param {string}   slug  The slug.
 */
export function getApprenticeship( state: AppState, slug: string ) {
	return state.apprenticeships.items[ slug ];
}

/**
 * @param {AppState} state Global application state.
 * @param {string}   slug  The slug.
 */
export function isApprenticeshipFetching( state: AppState, slug: string ) {
	return isEntityRecordsFetching( state, 'apprenticeships', { slug } );
}

/**
 * @param {AppState} state Global application state.
 * @param {string}   slug  The slug.
 */
export function getTrialApprenticeship( state: AppState, slug: string ) {
	return state.trialApprenticeships.items[ slug ];
}

/**
 * @param {AppState} state Global application state.
 * @param {string}   slug  The slug.
 */
export function isTrialApprenticeshipFetching( state: AppState, slug: string ) {
	return isEntityRecordsFetching( state, 'trialApprenticeships', { slug } );
}

/**
 * @param {AppState} state Global application state.
 * @param {string}   slug  The slug.
 */
export function getApprenticeshipProfession( state: AppState, slug: string ) {
	return state.apprenticeshipProfessions.items[ slug ];
}

/**
 * @param {AppState} state Global application state.
 * @param {string}   slug  The slug.
 */
export function isApprenticeshipProfessionFetching( state: AppState, slug: string ) {
	return isEntityRecordsFetching( state, 'apprenticeshipProfessions', { slug } );
}

/**
 * Get the current fetching state from a form input.
 *
 * @param {AppState} state Global application state.
 * @return {Boolean} True if form data is being fetched
 */
export function isFormFetching( state: AppState ) {
	return state.form.isFetching;
}

/**
 * Get the current fetching state from a form input.
 *
 * @param {AppState} state Global application state.
 * @return {String} Returns 'ok' if form was successfully fetched
 */
export function getFormResponse( state: AppState ) {
	return state.form.response || null;
}

/**
 * @param {AppState} state Global application state.
 */
export function isUserConnectionsFetching( state: AppState ) {
	return state.userConnections.isFetching;
}

/**
 * Returns a connection with the given ID.
 *
 * @param {AppState} state Global application state.
 * @param {number}   id    The connection ID.
 * @return {Object} The connection, or null if none exists.
 */
export const getUserConnection = createSelector(
	( state: AppState, id: number ) => {
		const connection = state.userConnections.results[ id ];
		if ( ! connection ) {
			return null;
		}

		return connection;
	},
	( state: AppState, id: number ) => [ state.userConnections.results[ id ] ],
);

/**
 * Returns an array of all connections.
 *
 * @param {AppState} state Global application state.
 * @return {Array} An array of all connections.
 */
export const getUserConnections = createSelector(
	( state: AppState ) => {
		return map( state.userConnections.results, ( value, id ) =>
			getUserConnection( state, Number( id ) ),
		);
	},
	( state: AppState ) => [ state.userConnections.results ],
);

/**
 * Returns a list of all remembered apprenticeship items.
 *
 * @param {AppState} state Global application state.
 * @return {Array} An array of all connections.
 */
export const getCurrentUserRememberedApprenticeshipItems = createSelector(
	( state: AppState ) => {
		const items = filter( state.currentUserRememberedItems.results, [ 'type', 'apprenticeship' ] );
		return items.map( ( item ) => item.id );
	},
	( state: AppState ) => [ state.currentUserRememberedItems.results ],
);

/**
 * Returns a list of all remembered event items.
 *
 * @param {AppState} state Global application state.
 * @return {Array} A list of all remembered event items.
 */
export const getCurrentUserRememberedEventItems = createSelector(
	( state: AppState ) => {
		const items = filter( state.currentUserRememberedItems.results, [ 'type', 'event' ] );
		return items.map( ( item ) => item.id );
	},
	( state: AppState ) => [ state.currentUserRememberedItems.results ],
);

/**
 * Returns a list of all remembered profession items.
 *
 * @param {AppState} state Global application state.
 * @return {Array} A list of all remembered profession items.
 */
export const getCurrentUserRememberedProfessionItems = createSelector(
	( state: AppState ) => {
		const items = filter( state.currentUserRememberedItems.results, [ 'type', 'profession' ] );
		return items.map( ( item ) => item.id );
	},
	( state: AppState ) => [ state.currentUserRememberedItems.results ],
);

/**
 * @param {AppState} state Global application state.
 * @return {Boolean} True if data is being fetched
 */
export function isCurrentUserRememberedItemsFetching( state: AppState ) {
	return state.currentUserRememberedItems.isFetching;
}

/**
 * Returns a list of all remembered apprenticeship items.
 *
 * @param {AppState} state Global application state.
 * @return {Array} An array of all connections.
 */
export const getUserRememberedApprenticeshipItems = createSelector(
	( state: AppState, id: number ) => {
		const results = state.userRememberedItems.results[ id ] || null;
		if ( ! results ) {
			return [];
		}
		const items = filter( results, [ 'type', 'apprenticeship' ] );
		return items.map( ( item ) => item.id );
	},
	( state: AppState, id: number ) => [ state.userRememberedItems.results, id ],
);

/**
 * Returns a list of all remembered event items.
 *
 * @param {AppState} state Global application state.
 * @return {Array} A list of all remembered event items.
 */
export const getUserRememberedEventItems = createSelector(
	( state: AppState, id: number ) => {
		const results = state.userRememberedItems.results[ id ] || null;
		if ( ! results ) {
			return [];
		}
		const items = filter( results, [ 'type', 'event' ] );
		return items.map( ( item ) => item.id );
	},
	( state: AppState, id: number ) => [ state.userRememberedItems.results, id ],
);

/**
 * Returns a list of all remembered profession items.
 *
 * @param {AppState} state Global application state.
 * @return {Array} A list of all remembered profession items.
 */
export const getUserRememberedProfessionItems = createSelector(
	( state: AppState, id: number ) => {
		const results = state.userRememberedItems.results[ id ] || null;
		if ( ! results ) {
			return [];
		}
		const items = filter( results, [ 'type', 'profession' ] );
		return items.map( ( item ) => item.id );
	},
	( state: AppState, id: number ) => [ state.userRememberedItems.results, id ],
);

/**
 * @param {AppState} state Global application state.
 * @return {Boolean} True if data is being fetched
 */
export function isUserRememberedItemsFetching( state: AppState, id: number ): boolean {
	return state.userRememberedItems.isFetching[ id ] || false;
}

/**
 * @param {AppState} state Global application state.
 * @param {number}   id    The ID of the user.
 */
export function getUser( state: AppState, id: number ) {
	return state.users.items[ id ];
}

/**
 * @param {AppState} state Global application state.
 * @param {number}   id    The ID of the user.
 * @return {Boolean} True if data is being fetched
 */
export function isUserFetching( state: AppState, id: number ): boolean {
	return state.users.isFetching[ id ] || false;
}

/**
 * @param {AppState} state Global application state.
 */
export function getLanguages( state: AppState ) {
	return state.languages.results;
}

/**
 * @param {AppState} state Global application state.
 */
export function isLanguagesFetching( state: AppState ) {
	return state.languages.isFetching || false;
}

/**
 * @param {AppState} state Global application state.
 */
export function getNationalities( state: AppState ) {
	return state.nationalities.results;
}

/**
 * @param {AppState} state Global application state.
 */
export function isNationalitiesFetching( state: AppState ): boolean {
	return state.nationalities.isFetching || false;
}

/**
 * @param {AppState} state Global application state.
 */
export function getCurrentUserActivities( state: AppState ): Activity[] {
	return state.currentUserActivities.results;
}

/**
 * @param {AppState} state Global application state.
 * @return {Boolean} True if data is being fetched
 */
export function isCurrentUserActivitiesFetching( state: AppState ): boolean {
	return state.currentUserActivities.isFetching;
}

/**
 * @param {AppState} state Global application state.
 * @param {number}   id    The ID of the user.
 */
export function getUserActivities( state: AppState, id: number ) {
	return state.userActivities.items[ id ];
}

/**
 * @param {AppState} state Global application state.
 * @param {number}   id    The ID of the user.
 * @return {Boolean} True if data is being fetched
 */
export function isUserActivitiesFetching( state: AppState, id: number ): boolean {
	return state.userActivities.isFetching[ id ] || false;
}

/**
 * @param {AppState} state Global application state.
 */
export function getNews( state: AppState ) {
	return state.news.results;
}

/**
 * @param {AppState} state Global application state.
 */
export function isNewsFetching( state: AppState ) {
	return state.news.isFetching || false;
}

/**
 * @param {AppState} state Global application state.
 * @param {string}   slug  The slug.
 */
export function getOrganization( state: AppState, slug: string ): AppStateOrganizationItem {
	return state.organizations.items[ slug ];
}

/**
 * @param {AppState} state Global application state.
 * @param {string}   slug  The slug.
 */
export function isOrganizationFetching( state: AppState, slug: string ): boolean {
	return isEntityRecordsFetching( state, 'organizations', { slug } );
}

/**
 * @param {AppState} state Global application state.
 * @param {string}   slug  The slug.
 */
export function isOrganizationUpdating( state: AppState, slug: string ): boolean {
	return state?.organizations?.isUpdating?.[ slug ] || false;
}

/**
 * @param {AppState} state Global application state.
 */
export function isApprenticeshipRequestCreating( state: AppState ): boolean {
	return state.apprenticeshipRequestOperations.isCreating;
}

/**
 * @param {AppState} state Global application state.
 */
export function isApprenticeshipSlotCreating( state: AppState ): boolean {
	return state.apprenticeshipSlotOperations.isCreating;
}

/**
 * @param {AppState} state Global application state.
 * @param {number}   id    The id.
 */
export function isApprenticeshipSlotUpdating( state: AppState, id: number ): boolean {
	return state.apprenticeshipSlotOperations.isUpdating[ id ] || false;
}

/**
 * @param {AppState} state Global application state.
 * @param {number}   id    The id.
 */
export function isApprenticeshipSlotDeleting( state: AppState, id: number ): boolean {
	return state.apprenticeshipSlotOperations.isDeleting[ id ] || false;
}

/**
 * @param {AppState} state Global application state.
 */
export function isEventCreating( state: AppState ): boolean {
	return state.eventOperations.isCreating;
}

/**
 * @param {AppState} state Global application state.
 * @param {number}   id    The id.
 */
export function isEventUpdating( state: AppState, id: number ): boolean {
	return state.eventOperations.isUpdating[ id ] || false;
}

/**
 * @param {AppState} state Global application state.
 * @param {number}   id    The id.
 */
export function isEventDeleting( state: AppState, id: number ): boolean {
	return state.eventOperations.isDeleting[ id ] || false;
}

/**
 * @param {AppState} state    Global application state.
 * @param {number}   idOrSlug The ID or slug of an organization.
 */
export function canEditOrganization( state: AppState, idOrSlug: number | string ): boolean {
	if ( true === state.profile?.data?.capabilities?.canEditOthersOrganizations ) {
		return true;
	}

	if ( isNumber( idOrSlug ) ) {
		return state.profile?.data?.organizations?.ids?.includes( idOrSlug ) || false;
	}

	return state.profile?.data?.organizations?.slugs?.includes( idOrSlug ) || false;
}

/**
 * @param {AppState} state Global application state.
 */
export function getMenus( state: AppState ): AppStateMenuItems {
	return state.menus.items;
}

/**
 * @param {AppState} state Global application state.
 */
export function isMenusFetching( state: AppState ) {
	return state.menus.isFetching;
}

/**
 * @param {AppState} state Global application state.
 * @param {string}   slug  The slug.
 */
export function getPost( state: AppState, slug ) {
	return state.posts.items[ slug ];
}

/**
 * @param {AppState} state Global application state.
 * @param {string}   slug  The slug.
 */
export function isPostFetching( state: AppState, slug ) {
	return isEntityRecordsFetching( state, 'posts', { slug } );
}
